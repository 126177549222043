// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyAlHBGQPjkrXtMSctyONAdzH1ukeLX8m68',
    authDomain: 'bofi-ba143.firebaseapp.com',
    databaseURL: 'https://bofi-ba143.firebaseio.com',
    projectId: 'bofi-ba143',
    storageBucket: 'bofi-ba143.appspot.com',
    messagingSenderId: '347001206874',
    appId: '1:347001206874:web:a05ea94838ae6cc01a0063',
    measurementId: 'G-JPCJ4BBL1W'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
